import React, { useCallback, useState } from 'react'
import {
  generateMergedStatsSlice,
  MergedSlice,
} from 'components/ps-chart/stores/connections-store/LinksTree/getChainedSliceTree'
import { twMerge } from 'tailwind-merge'
import styled from 'styled-components/macro'
import tw from 'twin.macro'
import { SearchStore } from 'components/ps-chart/actions-panel/SearchStore'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import {
  sortUsingSummaryType,
  stopMouseDown,
  TableCardChild,
  TableCardChildButton,
  transformSummary,
} from 'components/cco/StatsView'
import { EndpointData, EndpointSessionData, MethodData, SummaryDto } from 'components/cco/types'
import { TableSummaryView } from 'components/cco/TableStateToggleButton'
import { useCcoParams } from 'components/cco/useCcoParams'
import { Link } from 'react-router-dom'
import { useFeatureFlag, FEATURE_FLAGS } from 'utils/feature-flags'

export const TableColumnText = styled.span<{ right: boolean }>`
  direction: ${({ right }) => (right ? 'rtl' : 'ltl')};
`

export const TableColumnContent = styled.div`
  ${tw`flex items-center h-[100%] hover:bg-dark-dark3`}
`

const TableRowContainer = ({
  data,
  containerClassName,
  firstTextClassName,
  otherTextClassName,
  onMouseDown = () => {},
  onMouseUp = () => {},
}: {
  data: string[]
  containerClassName: string
  firstTextClassName: string
  otherTextClassName: string
  onMouseUp: () => void
  onMouseDown: () => void
}) => {
  return (
    <div
      className={twMerge('px-[8px] mb-[2px] rounded-[4px]', 'grid grid-cols-8', containerClassName)}
    >
      {data.length &&
        data.map((item, index) => (
          <div
            key={index}
            className={`${index === 0 ? 'col-span-4 *:truncate' : 'col-span-1'} flex items-center`}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
          >
            <TableColumnText
              className={twMerge('px-[8px]', index === 0 ? firstTextClassName : otherTextClassName)}
              right={index === 0}
            >
              {item}
            </TableColumnText>
          </div>
        ))}
    </div>
  )
}

export const TableEndpointMethodsElement = ({
  data,
  methods,
  sessions,
  summaryViewMode,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  psChartStore,
}: {
  data: string[]
  methods: MethodData[]
  sessions: EndpointSessionData[]
  summaryViewMode: TableSummaryView
  psChartStore: PsChartStore
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [mouseDownTime, setMouseDownTime] = useState(0)

  const handleMouseDown = useCallback(() => {
    setMouseDownTime(Date.now())
  }, [])

  const handleClick = useCallback(() => {
    const duration = Date.now() - mouseDownTime

    // Threshold duration to differentiate between click and text selection
    const threshold = 200 // milliseconds

    if (duration < threshold) {
      setIsOpen((state) => !state)
    }
  }, [mouseDownTime, setIsOpen])

  return (
    <>
      {!isOpen ? (
        <TableRowContainer
          onMouseDown={handleMouseDown}
          onMouseUp={handleClick}
          data={data}
          containerClassName="bg-dark-dark1 hover:bg-dark-dark2 h-[56px]"
          firstTextClassName="text-[16px] leading-[22px] text-gray-service"
          otherTextClassName="text-[12px] leading-[17px] text-gray-service"
        />
      ) : (
        <TableEndpointMethodsCardContainer
          onMouseDown={handleMouseDown}
          onMouseUp={handleClick}
          data={data}
          methods={methods}
          sessions={sessions}
          summaryViewMode={summaryViewMode}
        />
      )}
    </>
  )
}

export const TableEndpointMethodsCardContainer = ({
  data,
  methods,
  sessions,
  summaryViewMode,
  onMouseDown = () => {},
  onMouseUp = () => {},
}: {
  data: string[]
  methods: MethodData[]
  sessions: EndpointSessionData[]
  summaryViewMode: TableSummaryView
  onMouseUp: () => void
  onMouseDown: () => void
}) => {
  const { ccoSessionPath } = useCcoParams()

  const [methodsExpander, setMethodsExpander] = useState(1)
  const [sessionsExpander, setSessionsExpander] = useState(1)

  const expandMethods = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      setMethodsExpander((prevExpander) => prevExpander + 1)
    },
    [setMethodsExpander],
  )

  const collapseMethods = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      setMethodsExpander(1)
    },
    [setMethodsExpander],
  )

  const expandSessions = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      setSessionsExpander((prevExpander) => prevExpander + 1)
    },
    [setSessionsExpander],
  )

  const collapseSessions = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      setSessionsExpander(1)
    },
    [setSessionsExpander],
  )

  return (
    <div className="px-[8px] pb-[16px] mb-[2px] rounded-[4px] bg-dark-dark2">
      <div className="h-[56px] border-b-[1px] border-b-[#383838] grid grid-cols-8">
        {data.length &&
          data.map((item, index) => (
            <div
              key={index}
              className={`${
                index === 0 ? 'col-span-4 *:truncate' : 'col-span-1'
              } flex items-center`}
              onMouseDown={onMouseDown}
              onMouseUp={onMouseUp}
            >
              <TableColumnText
                className={twMerge(
                  'px-[8px]',
                  index === 0
                    ? 'text-[16px] leading-[22px] font-semibold'
                    : 'text-[12px] leading-[17px]',
                )}
                right={index === 0}
              >
                {item}
              </TableColumnText>
            </div>
          ))}
      </div>
      <>
        {methods
          .slice(0, 10 * methodsExpander)
          .map(({ title, cpuTime, storage, traffic, count, type }, index) => (
            <TableCardChild
              key={index}
              data={[
                title,
                ...transformSummary({ cpuTime, traffic, storage, count }, summaryViewMode),
              ]}
              methodType={type}
            >
              {methodsExpander > 1 &&
                index === Math.min(methods.length - 1, 10 * methodsExpander - 2) && (
                  <TableCardChildButton onClick={collapseMethods} onMouseDown={stopMouseDown}>
                    Collapse
                  </TableCardChildButton>
                )}
              {methods.length / 10 >= methodsExpander && index === 10 * methodsExpander - 1 && (
                <TableCardChildButton onClick={expandMethods} onMouseDown={stopMouseDown}>
                  Expand
                </TableCardChildButton>
              )}
            </TableCardChild>
          ))}
        {sessions
          .slice(0, 10 * sessionsExpander)
          .map(({ title, cpuTime, storage, traffic, count }, index) => (
            <TableCardChild
              key={index}
              data={[
                <Link to={ccoSessionPath(title)}>{title}</Link>,
                ...transformSummary({ cpuTime, traffic, storage, count }, summaryViewMode),
              ]}
              lastItem={index === Math.min(sessions.length - 1, 10 * sessionsExpander - 1)}
            >
              {index === 0 && (
                <span className="text-[12px] leading-[17px] font-semibold text-white">
                  User Sessions
                </span>
              )}
              {sessionsExpander > 1 &&
                index === Math.min(methods.length - 1, 10 * methodsExpander - 2) && (
                  <TableCardChildButton onClick={collapseSessions} onMouseDown={stopMouseDown}>
                    Collapse
                  </TableCardChildButton>
                )}
              {sessions.length / 10 >= sessionsExpander && index === 10 * sessionsExpander - 1 && (
                <TableCardChildButton onClick={expandSessions} onMouseDown={stopMouseDown}>
                  Expand
                </TableCardChildButton>
              )}
            </TableCardChild>
          ))}
      </>
    </div>
  )
}

const TableEndpointTreeElement = ({
  tree,
  summaryViewMode,
}: {
  tree: MergedSlice
  summaryViewMode: TableSummaryView
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [mouseDownTime, setMouseDownTime] = useState(0)

  const handleMouseDown = useCallback(() => {
    setMouseDownTime(Date.now())
  }, [])

  const handleClick = useCallback(() => {
    const duration = Date.now() - mouseDownTime
    const threshold = 200 // milliseconds
    if (duration < threshold) {
      setIsOpen((state) => !state)
    }
  }, [mouseDownTime])

  // Prepare data for the root node
  const data = [
    tree.title,
    ...transformSummary(
      {
        cpuTime: tree.summary.processedTime,
        traffic: 0,
        storage: 0,
        count: tree.summary.count,
      },
      summaryViewMode,
    ),
  ]

  return (
    <>
      {!isOpen ? (
        <TableRowContainer
          onMouseDown={handleMouseDown}
          onMouseUp={handleClick}
          data={data}
          containerClassName="bg-dark-dark1 hover:bg-dark-dark2 h-[56px]"
          firstTextClassName="text-[16px] leading-[22px] text-gray-service"
          otherTextClassName="text-[12px] leading-[17px] text-gray-service"
        />
      ) : (
        <>
          {/* Render the root node as a card */}
          <div className="px-[8px] pb-[16px] mb-[2px] rounded-[4px] bg-dark-dark2">
            {/* Header */}
            <div
              className="h-[56px] border-b-[1px] border-b-[#383838] grid grid-cols-8 items-center"
              onMouseDown={handleMouseDown}
              onMouseUp={handleClick}
            >
              {/* Render the root node data */}
              {data.map((item, index) => (
                <div
                  key={index}
                  className={`${index === 0 ? 'col-span-4' : 'col-span-1'} flex items-center`}
                >
                  <TableColumnText
                    className={twMerge(
                      'px-[8px]',
                      index === 0
                        ? 'text-[16px] leading-[22px] font-semibold'
                        : 'text-[12px] leading-[17px]',
                    )}
                    right={index === 0}
                  >
                    {item}
                  </TableColumnText>
                </div>
              ))}
            </div>
            {tree.links.map((childNode, index) => (
              <ChainTreeView
                key={index}
                node={childNode}
                level={0}
                summaryViewMode={summaryViewMode}
              />
            ))}
          </div>
        </>
      )}
    </>
  )
}

const ChainTreeView = ({
  node,
  level,
  summaryViewMode,
}: {
  node: MergedSlice
  level: number
  summaryViewMode: TableSummaryView
}) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const handleToggle = (event: React.MouseEvent) => {
    event.stopPropagation()
    setIsExpanded((prev) => !prev)
  }

  const indentSize = 16 // Adjust as needed for indentation

  const hasChildren = node.links && node.links.length > 0

  // Prepare the title element with expand/collapse button and indentation
  const titleElement = (
    <div className="flex items-center" style={{ marginLeft: level * indentSize }}>
      {hasChildren && (
        <span onClick={handleToggle} className="cursor-pointer mr-1 select-none">
          {isExpanded ? '-' : '+'}
        </span>
      )}
      <span>{node.title}</span>
    </div>
  )

  // Prepare the data array for TableCardChild
  const data = [
    titleElement,
    ...transformSummary(
      {
        cpuTime: node.summary.processedTime,
        traffic: 0,
        storage: 0,
        count: node.summary.count,
      },
      summaryViewMode,
    ),
  ]

  return (
    <>
      <TableCardChild data={data}>
        {/* Optional child elements, such as Expand/Collapse buttons if needed */}
      </TableCardChild>

      {/* Render child nodes if expanded */}
      {isExpanded &&
        node.links.map((childNode, index) => (
          <ChainTreeView
            key={index}
            node={childNode}
            level={level + 1}
            summaryViewMode={summaryViewMode}
          />
        ))}
    </>
  )
}

function createUniqueKey(title: string, summary: SummaryDto) {
  const titlePart = title.slice(0, 5)
  const summaryPart = Object.values(summary)
    .filter((value) => typeof value === 'number')
    .join('')

  return `${titlePart}-${summaryPart}`
}

export const TableEndpointList = ({
  endpoints,
  summaryViewMode,
  sortType,
  sortInvertDirection,
  psChartStore,
}: {
  endpoints: EndpointData[]
  summaryViewMode: TableSummaryView
  sortType: keyof SummaryDto
  sortInvertDirection: boolean
  psChartStore: PsChartStore
}) => {
  const isTreeViewActive = useFeatureFlag(FEATURE_FLAGS.CCO_TREE_VIEW_TABLE)
  return (
    <>
      {endpoints.map(({ title, sessions, directMethods, indirectMethods, ...summary }) => {
        if (!isTreeViewActive) {
          const methods = [...directMethods, ...indirectMethods].sort((x, y) =>
            sortUsingSummaryType(x, y, sortType, sortInvertDirection),
          )
          return (
            <TableEndpointMethodsElement
              key={createUniqueKey(title, summary)}
              data={[title, ...transformSummary({ ...summary }, summaryViewMode)]}
              methods={methods}
              sessions={sessions}
              summaryViewMode={summaryViewMode}
              psChartStore={psChartStore}
            />
          )
        } else {
          const endpointSliceIds = SearchStore.manualSearchResults(title, psChartStore.sliceById)
          const endpointTree = generateMergedStatsSlice(endpointSliceIds, psChartStore)
          return (
            <TableEndpointTreeElement
              key={createUniqueKey(title, summary)}
              tree={endpointTree}
              summaryViewMode={summaryViewMode}
            />
          )
        }
      })}
    </>
  )
}
